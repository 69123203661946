import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBrWF5NPnb4gxyIM9Qz9TVsgjWmkBpNh4w",
  authDomain: "gerda-12727.firebaseapp.com",
  projectId: "gerda-12727",
  storageBucket: "gerda-12727.firebasestorage.app",
  messagingSenderId: "654252917410",
  appId: "1:654252917410:web:6b6389fe0cffb9a9ab0dfb",
};

initializeApp(firebaseConfig);
const db = getFirestore();

export { db };

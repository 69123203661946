import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import HomePage from "./pages/home/HomePage";
import Contact from "./pages/contact/Contact";
import Footer from "./components/footer/Footer";
import HowItWorks from "./pages/how-it-works/HowItWorks";
import AboutUs from "./pages/about-us/AboutUs";
import Firebase from "./pages/firebase/Firebase";
// import Payment from "./pages/pay-as-you-go/Payment";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/firebase" element={<Firebase />} />
        {/* <Route path="/payment" element={<Payment />} /> */}
        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import { db } from "../../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

const Firebase = () => {
  const [transportations, setTransportations] = useState(null);

  useEffect(() => {
    const ref = collection(db, "transportations");

    getDocs(ref).then((snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      setTransportations(results);
    });
  }, []);
  return (
    <div>{transportations ? transportations[0].steps[0] : "didnt find"}</div>
  );
};

export default Firebase;
